<template>
  <div class="s-orders-page">
    <div class="s-orders-nav">
      <div class="s-flex-basis-25">
        <button class="s-orders-btn ml-3 pl-3 pr-3" @click="backToIndex">
          返回
        </button>
      </div>
      <div class="text-center s-flex-basis-50">訂單明細</div>
    </div>

    <Loading v-if="fetching"></Loading>
    <div v-else>
      <div v-if="order" class="p-2 d-flex justify-content-around s-order-detail">
        <div class="mt-2">
          <p class="s-text-gray-darker s-whitespace-nowrap">
            訂購日 : <span>{{ createdDateTime }}</span>
          </p>
          <p class="s-text-gray-darker s-whitespace-nowrap">
            訂單號 : <span>{{ order.outer_order_number }}</span>
          </p>
          <p class="s-text-gray-darker s-whitespace-nowrap">
            取貨期限 : <span v-if="order">{{ deadlineDate }}</span>
          </p>
          <p class="s-whitespace-nowrap s-flex-basis-50 s-text-gray-darker">
            {{ deliveryText }}
          </p>
          <p class="s-font-bold s-orders-flex-line s-flex-basis-50 text-right">
            <span class="s-text-gray-darker s-mr-2">共 {{ quantity }} 件</span>
            <span class="s-text-secondary">{{ $currency(order.total) }}</span>
          </p>
        </div>

        <div class="d-flex flex-column justify-content-center s-space-y-4">
          <button
            class="s-orders-btn s-orders-btn--secondary"
            @click.prevent="$refs.confirm.open()"
            v-if="status === 'processing' && order.source_type === 3"
          >
            取消訂單
          </button>

          <button
            class="s-orders-btn s-orders-btn--secondary"
            @click="redirectToPoyaReturnUrl()"
            v-if="status === 'processing' &&
              (order.source_type === 1 || order.source_type === 2) &&
              order.status === 'WaitingToShipping'
              "
          >
            取消訂單
          </button>

          <button
            class="s-orders-btn"
            @click="modifyOrder"
            v-if="status === 'processing' && order.source_type === 3"
          >
            修改數量
          </button>
        </div>
      </div>

      <ul class="s-orders-tab">
        <li>
          <router-link :to="{ hash: 'order-items' }" :class="{ active: $route.hash == '' }">
            商品明細
          </router-link>
        </li>
        <li v-if="order.invoices.length > 0">
          <router-link :to="{ hash: 'invoices' }">
            發票資訊
          </router-link>
        </li>
      </ul>
      <!-- 商品明細 -->
      <template v-if="$route.hash == '#order-items' || $route.hash == ''">
        <ul class="s-mb-6">
          <li v-for="(item, i) in order.items" :key="i">
            <div class="s-order-item align-items-center">
              <div class="s-order-item-image">
                <div class="s-order-item-image-wrapper">
                  <img :src="image(item)" />
                </div>
              </div>
              <div
                class="s-order-item-info s-space-y-2 s-text-sm s-text-gray-darker"
              >
                <p class="">{{ item.title }}</p>
                <p class="s-whitespace-nowrap">
                  {{ item.outer_order_item_number }}
                </p>
                <p>{{ item.quantity }} 件 {{ $currency(item.total_price) }}</p>
              </div>
            </div>
          </li>
        </ul>
      </template>

      <!-- 發票 -->
      <template v-if="$route.hash == '#invoices'">
        <ul class="s-mb-6">
          <li>
            <ul class="s-invoice-item s-text-sm s-text-gray-darker s-space-y-2" v-for="(invoice, i) in order.invoices" :key="i">
              <li>發票號碼：{{ invoice.invoice_number }} </li>
              <li>發票時間：{{ invoiceDateDisplay(invoice) }} </li>
              <li>發票總金額： {{ $currency(invoice.total_price) }} </li>
            </ul>
          </li>
        </ul>
        <div>
          <div class="s-text-sm s-text-gray s-leading-normal s-text-justify">
            <div>提醒您</div>
            <div>訂單明細金額為透過 POYA 團購系統下單之金額</div>
            <div>如您於門市取貨時有與其它商品合併結帳</div>
            <div>則發票資訊將顯示為實際購物消費總額</div>
          </div>
        </div>
      </template>

      <Modal ref="confirm" title="您是否要取消訂單？">
        訂單取消後無法復原<br>如仍要購買需重新下單
        <template #actions="{ close }">
          <div class="s-flex s-space-x-3">
            <button
              class="s-btn s-btn-secondary"
              type="button"
              @click="cancelOrder"
              :disabled="fetching"
            >
              確認取消
            </button>
            <button class="s-btn s-btn-primary" type="button" @click="close">
              保留訂單
            </button>
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import Modal from "@/components/Page/Liff/Shared/Modal";
import Loading from "@/components/Page/Liff/Shared/Loading";
import withFetcher from "@/mixins/liff/withFetcher";

export default {
  mixins: [withFetcher],
  components: { Loading, Modal },
  inject: ["$currency", "$alert"],
  data: () => ({
    order: {
      items: [],
    },
    status: null,
    fetching: true,
  }),
  computed: {
    quantity() {
      if (this.order?.items.length === 0) {
        return 0;
      }
      return this.order?.items.map((el) => el.quantity).reduce((a, b) => a + b);
    },
    deliveryText() {
      const delType = {
        Home: "宅配取貨",
        LocationPickup: "門市自取 : ",
      };
      let delTypeText = delType[this.order.delivery_type];
      if (!this.order.delivery_type) {
        delTypeText = '取貨方式 : '
      }
      return delTypeText + (this.order.branch_name ?? this.order.outer_branch_name ?? '');
    },
    createdDateTime() {
      return moment(this.order?.created_at).format("YYYY/MM/DD");
    },
    deadlineDate() {
      if (this.order.deadline_at) {
        return moment(this.order.deadline_at).format("YYYY/MM/DD");
      } else {
        return "無";
      }
    },
  },
  methods: {
    ...mapActions({
      show: "liffGroupBuying/getOrderV2",
      cancel: "liffGroupBuying/cancelOrderV2",
    }),
    async fetch() {
      const data = await this.show({ id: this.$route.params.id });
      this.order = data;
      this.status = this.$route.params.status
    },
    backToIndex() {
      this.$router.push({
        name: "LiffGroupBuyingOrdersV2Status",
        params: { status: this.status },
      });
    },
    image(item) {
      const images = item?.product?.images || [{}];
      return images[0].pic_url;
    },
    async cancelOrder() {
      if (this.fetching) return;

      try {
        this.fetching = true;
        await this.cancel({ id: this.$route.params.id });

        this.$router.push({
          name: "LiffGroupBuyingBranchOrderCanceled",
          params: { id: this.order.groupbuying_order.groupbuying.id, order_id: this.order.id },
        });
      } catch ({ message }) {
        this.fetching = false;
        return this.$alert(message);
      }
    },
    modifyOrder() {
      this.$router.push({
        name: "LiffGroupBuyingOrdersV2Edit",
        params: { id: this.order.id }
      })
    },
    redirectToPoyaReturnUrl () {
      const url = this.order.is_original_org ? 'https://www.poyabuy.com.tw/V2/TradesOrder/TradesOrderList?shopId=40916' : 'https://www.poyahomebuy.com.tw/V2/TradesOrder/TradesOrderList?shopId=40917'
      window.location.href = url;
    },
    invoiceDateDisplay(invoice) {
      return moment(invoice.invoice_date).format("YYYY-MM-DD HH:mm");
    }
  },
};
</script>

<style lang="scss" scoped>
.s-orders-page {
  margin-top: -1.5rem;
}

.s-orders-nav {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
  padding: 0.75rem 0;
  border-bottom: solid 1px var(--s-gray-lighter);
  background-color: white;
}

.s-orders-flex-line {
  display: flex;
}

.s-order-detail {
  border-bottom: solid 1px var(--s-gray-lighter);
}

.s-order-item {
  padding: 0.75rem 0;
  border-bottom: solid 1px var(--s-gray-lighter);
  display: flex;
  min-height: 7rem;
}

.s-order-item-image {
  flex-shrink: 0;
  margin-right: 0.5rem;
  border-radius: 0.375rem;
  font-size: 0;
}

.s-order-item-image-wrapper {
  width: 5rem;
  height: 5rem;
  margin: 0.25rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
  background-color: var(--s-white);
}

.s-order-item-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.s-order-item-info {
  margin-right: 0.5rem;
}

.s-order-item-tag {
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 5.5rem;
}

.s-tag {
  text-align: center;
}

.s-flex-basis-25 {
  flex-basis: 25%;
}

.s-flex-basis-50 {
  flex-basis: 50%;
}

.s-flex-basis-75 {
  flex-basis: 75%;
}

.s-orders-btn {
  color: var(--s-gray);
  border: 1px solid var(--s-gray);
  text-decoration: none;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.5;
  padding: 2px 6px;

  &--secondary {
    color: var(--s-secondary);
    border: 1px solid var(--s-secondary);
  }
}

.s-orders-tab {
    display: flex;
    align-items: flex-end;
    background-color: var(--s-white);
  }

  .s-orders-tab li {
    flex: 1 1 0;
  }

  .s-orders-tab li a {
    display: block;
    padding: 0.75rem 0;
    text-align: center;
    border-bottom: solid 1px var(--s-gray-lighter);
  }

  .s-orders-tab li a.active {
    color: var(--s-secondary);
    font-weight: var(--s-weight-semibold);
    border-bottom: solid 2px var(--s-secondary);
    padding-bottom: calc(0.75rem - 1px);
  }


  .s-invoice-item {
    padding: 0.75rem 0;
    border-bottom: solid 1px var(--s-gray-lighter);
  }
</style>
